import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TabPanel data-id="Overview" mdxType="TabPanel">
      <h2 {...{
        "id": "form-instructions"
      }}>{`Form Instructions`}</h2>
      <p>{`Accessible forms have clear instructions that are preferably positioned near the top of the form, before the form’s input fields. Examples include explaining how required fields are represented visually in the form. Required fields must be indicated visually in a way that does not depend on color perception, and must be indicated programmatically for assistive technologies that need to describe such constraints to users. `}</p>
      <p>{`Field formatting requirements must also be included, preferably as part of a field label to ensure that assistive technology will describe the necessary formatting. Using placeholder text may be acceptable, but includes some distinct disadvantages: `}</p>
      <ol>
        <li parentName="ol">{`Assistive technology may not always describe the placeholder value, particularly when there is already input entered into the field.   `}</li>
        <li parentName="ol">{`Placeholder values typically include low contrast colors and are particularly difficult for low vision or color blind users to perceive. When contrast is increased, users are more likely to mistake placeholder values for real form input. `}</li>
      </ol>
      <p>{`For the reasons listed above, we recommend reserving placeholder values specifically for examples of valid field input.  `}</p>
      <p>{`If additional information is needed to understand or complete a form field, display this information in context of the form field, rather than as a separate document. Finding “buried” information can be confusing for some users and may lead to higher form error rate. `}</p>
      <h3 {...{
        "id": "hidden-read-only--disabled-form-controls"
      }}>{`Hidden, Read-Only, & Disabled Form Controls`}</h3>
      <p>{`All disabled form controls are explicitly exempt from the `}<a parentName="p" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
        }}>{`minimum contrast guidelines`}</a>{` set in WCAG 2.2. The low contrast colors are likely to be difficult for low vision and color blind users to perceive the values of the disabled control. `}</p>
      <p>{`Disabling Buttons that submit a form to block the submission of invalid data carries a risk of blocking users from submitting the form if they cannot understand what is missing or needs to be corrected. For this reason, we do not recommend disabled form submit buttons.   `}</p>
      <ul>
        <li parentName="ul">{`Showing controls on a “need to know” basis, that is, hiding form controls that users do not have the required permissions to access, can reduce the complexity of the page and reduce cognitive load.  `}</li>
        <li parentName="ul">{`Setting a control to read-only or replacing it entirely with static text is preferable to disabled controls because of the higher contrast when users need to view information about a control and don’t have the required permissions to modify it.  `}</li>
        <li parentName="ul">{`Provide an explanation for why the field is disabled and how to proceed when a field is temporarily unavailable or requires action from users to change the disabled status.   `}</li>
        <li parentName="ul">{`All disabled form controls are removed from the keyboard focus order of the screen. While this is standard behavior according to HTML specification, `}<strong parentName="li">{`it is not true`}</strong>{` that screen reader users won’t be able to access disabled input. All screen readers are capable of detecting disabled form controls even if the controls cannot be focused with the keyboard. `}</li>
      </ul>
      <h2 {...{
        "id": "keyboard-accessibility"
      }}>{`Keyboard Accessibility`}</h2>
      <p>{`Pointing devices, like a mouse or trackpad, are not universally accessible devices. Why? A pointing device requires some functional eyesight to perceive the on-screen pointer, and fine motor control to precisely move the pointer. An accessible form is a form that can be completed accurately using a variety of alternative input devices including a keyboard, a custom switch control, and even by interpreting users voice commands.`}</p>
      <p>{`For more information about keyboard interactions, please refer to the Accessibility Guidelines section for each individual `}<a parentName="p" {...{
          "href": "/components/overview"
        }}>{`component`}</a>{`.`}</p>
      <h2 {...{
        "id": "label-placement"
      }}>{`Label Placement`}</h2>
      <p>{`Labels describe the purpose or function of a form control such as a Text Input or Checkbox. Labels for most form controls must be positioned to the left or directly above the form control; Radio Button and Checkbox labels must be positioned to the right of the control. The aim should be to maintain a close and distinct visual relationship between the label and the form control.`}</p>
      <p>{`Placing labels above the form controls help reduce horizontal scrolling for people with low vision and mobile device users. `}</p>
      <h3 {...{
        "id": "grouping-fields"
      }}>{`Grouping Fields`}</h3>
      <p>{`Grouping fields together isn’t always necessary or recommended. While designing an accessible form, examine where it is necessary to use repeated sets of identical input fields that need a little extra context to describe the purpose of each group. For example: `}</p>
      <ul>
        <li parentName="ul">{`Using 3 Text Input fields to capture the month, day, and year values of a “start date” and “end date”`}</li>
        <li parentName="ul">{`Using 2 Text Input fields to capture the time of day`}</li>
        <li parentName="ul">{`A form capturing both a billing address and a shipping address`}</li>
      </ul>
    </TabPanel>
    <TabPanel data-id="For Designers" mdxType="TabPanel">
      <h2 {...{
        "id": "multi-step-forms--wizards"
      }}>{`Multi-Step Forms / Wizards`}</h2>
      <p>{`Wherever possible, forms must be divided into multiple small sections of related inputs. This helps make long forms less daunting and easier to understand, particularly for people who are less experienced using computers or who have various cognitive disabilities.`}</p>
      <ul>
        <li parentName="ul">{`Each section of the multi-step form should have 5-10 form controls at most.`}</li>
        <li parentName="ul">{`Form level instructions must be repeated at the beginning of every section.`}</li>
        <li parentName="ul">{`Multi-step forms must include a progress bar, step count, or percent complete indicator so users can understand their progress through the task.`}</li>
        <li parentName="ul">{`Optional form controls or groups of controls must be clearly communicated. Optional sections must have a mechanism to skip the page.`}</li>
        <li parentName="ul">{`If possible, don’t set a time limit to fill out the form. If a time limit is required, users must be able to adjust or extend the time limit.`}</li>
      </ul>
      <h3 {...{
        "id": "redundant-data-entry"
      }}>{`Redundant Data Entry`}</h3>
      <p>{`Information that the user has previously entered must be made available to them, so that users are not entering repetitive information. Previously entered information can be made available through a select dropdown, or by allowing users to copy-paste or by auto-populating inputs with previously entered values.`}</p>
      <p>{`The intent of which is to reduce the cognitive impact to users when information is asked for repeatedly in multi-step process flows. Allowing browsers to auto-fill commonly entered information, such as names and addresses, can also help reduce the cognitive load and effort on users. `}</p>
      <p>{`Examples include ecommerce sites that give users a checkbox to specify that the billing address is going to be identical to the shipping address.`}</p>
    </TabPanel>
    <TabPanel data-id="For Developers" mdxType="TabPanel">
      <h2 {...{
        "id": "associating-labels-to-form-controls"
      }}>{`Associating Labels to Form Controls`}</h2>
      <p>{`Sighted users can make a visual association between an input and its label by the form layout, positioning, and the label’s proximity to the input field. This association must be programmatically determinable and properly exposed to platform accessibility API’s for assistive technologies to perform properly. `}</p>
      <p>{`Users depending on screen readers are likely to move through form controls quickly using the Tab key, and text that is not associated with any field is likely to be skipped and not described to users at all. For this reason, form instructions are best positioned before the form begins, and inline messages are associated with their associated input’s long description. `}</p>
      <p>{`In most common situations, the `}<inlineCode parentName="p">{`<label>`}</inlineCode>{` element must be used with the `}<inlineCode parentName="p">{`for`}</inlineCode>{` attribute that will reference the unique `}<inlineCode parentName="p">{`id`}</inlineCode>{` value of the associated form input. Other methods can also be applied assigning labels to form controls, please refer to the `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessibility-tree#accessible-names-and-descriptions"
        }}>{`Accessible Name and Description`}</a>{` for technical details.`}</p>
      <h3 {...{
        "id": "grouping-fields-with-fieldset-and-legend"
      }}>{`Grouping Fields with Fieldset and Legend`}</h3>
      <p>{`Accessible forms sometimes require related form inputs to be grouped together for providing additional context. The most common example is using `}<inlineCode parentName="p">{`<fieldset>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<legend>`}</inlineCode>{` elements to group related radio buttons together. This principle can be applied anytime multiple inputs are used to represent a larger field or when multiple sections of a form require otherwise indistinguishable input. For example, a form capturing both a billing address and a shipping address. `}</p>
      <h2 {...{
        "id": "required-inputs"
      }}>{`Required Inputs`}</h2>
      <p>{`Required input must be programmatically determinable for assistive technologies to perform properly. This is done by using the HTML `}<inlineCode parentName="p">{`required`}</inlineCode>{` attribute or by using the ARIA `}<inlineCode parentName="p">{`aria-required`}</inlineCode>{` property.  `}</p>
      <h2 {...{
        "id": "autocomplete"
      }}>{`Autocomplete`}</h2>
      <p>{`Allowing web browsers to programmatically auto-fill form inputs by using the HTML `}<inlineCode parentName="p">{`autocomplete`}</inlineCode>{` attribute is a valuable technique for reducing cognitive load and effort required for completing a task. `}</p>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      